var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-table", {
    staticClass: "bordered",
    attrs: {
      "data-testId": _vm.testId,
      "show-empty": "",
      responsive: "",
      striped: "",
      "empty-text": _vm.$t("Nenhum produto adicionado"),
      fields: _vm.fields,
      items: _vm.items,
      "details-td-class": "py-0 px-1",
      "td-style": function (value, key, item) {
        return key === "quantity"
          ? {
              width:
                item.quantityCold !== item.quantityNatural ? "180px" : "60px",
            }
          : {}
      },
    },
    scopedSlots: _vm._u([
      {
        key: "cell(action)",
        fn: function (row) {
          return [
            _c("e-grid-actions", {
              attrs: {
                "show-update": _vm.showUpdateAction(row.item),
                "show-delete": _vm.showRemoveAction(row.item),
              },
              on: {
                update: function ($event) {
                  return _vm.onShowProductSidebar(row.item)
                },
                delete: function ($event) {
                  return _vm.onRemoveProduct(row.item)
                },
              },
            }),
          ]
        },
      },
      {
        key: "cell(skuProduct)",
        fn: function (row) {
          return [
            row.item.deliveredDate
              ? _c("b-badge", { attrs: { variant: "success" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("Entregue")) + " "),
                ])
              : _vm._e(),
            _c("e-product-router-link", {
              attrs: {
                "sku-id": row.item.skuProduct.id,
                "product-id": row.item.skuProduct.productId,
                "product-name": row.item.skuProduct.name,
                ean: row.item.skuProduct.ean,
                "is-kit": row.item.isKit,
              },
            }),
          ]
        },
      },
      {
        key: "cell(quantity)",
        fn: function (row) {
          return [
            _c("span", [_vm._v(_vm._s(row.item.quantity))]),
            row.item.quantityCold != row.item.quantityNatural
              ? _c("em", [
                  row.item.quantityNatural
                    ? _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("Natural") +
                                ": " +
                                row.item.quantityNatural
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  row.item.quantityCold
                    ? _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("Gelada") + ": " + row.item.quantityCold
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]
        },
      },
      {
        key: "row-details",
        fn: function (row) {
          return [
            _c(
              "div",
              [
                row.item.kitItems.length > 0
                  ? _c("b-table", {
                      staticClass: "bordered mb-1",
                      attrs: {
                        "show-empty": "",
                        responsive: "",
                        striped: "",
                        fields: _vm.kitSubfields,
                        items: _vm.getKitItems(row.item.kitItems),
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(name)",
                            fn: function (rowSku) {
                              return [
                                _c("e-product-router-link", {
                                  attrs: {
                                    "sku-id": rowSku.item.skuProduct.id,
                                    "product-id":
                                      rowSku.item.skuProduct.productId,
                                    "product-name": rowSku.item.skuProduct.name,
                                    ean: rowSku.item.skuProduct.ean,
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  : _vm._e(),
                row.item.lendingProducts && row.item.lendingProducts.length > 0
                  ? _c("b-table", {
                      staticClass: "bordered mb-1",
                      attrs: {
                        "show-empty": "",
                        responsive: "",
                        striped: "",
                        fields: _vm.lendingSubfields,
                        items: row.item.lendingProducts,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(name)",
                            fn: function (rowSku) {
                              return [
                                _c("e-product-router-link", {
                                  attrs: {
                                    "sku-id": rowSku.item.skuProduct.id,
                                    "product-id":
                                      rowSku.item.skuProduct.productId,
                                    "product-name": rowSku.item.skuProduct.name,
                                    ean: rowSku.item.skuProduct.ean,
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }